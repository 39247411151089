import React from "react"
import Layout, { MyLocationEnum } from "../components/Layout"
import SEO from "../components/Seo"
import styled from 'styled-components'

const NotFoundPage = ({ location }) => {
  return(
    <Layout location={location} myLocation={MyLocationEnum._404LostAndAlone}>
      <SEO title="404: Not found" />
      <Container>
        <h1>404</h1>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
	position: relative;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	perspective: 2000px;
	pointer-events: auto;
`

export default NotFoundPage
